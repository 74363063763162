<template>
  <div class="columns no-margin">
    <div class="column no-padding is-half is-offset-one-quarter">
      <div class="card">
        <header class="card-header has-background-primary">
          <div @click.stop="$router.go(-1)" class="card-header-icon">
            <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
          </div>
          <p class="card-header-title has-text-light">INFORMASI PASIEN</p>
        </header>
        <div class="card-content events-card">
          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">No. RM</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ pasien.no_rm }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Nama</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="capitalize column detail has-text-left is-capitalized">{{ pasien.nama }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Gender</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ pasien.gender }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Tgl Lahir</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ pasien.tgl_lahir }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Catatan</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ pasien.catatan }}</div>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import Pasien from "../models/pasien.js";

export default {
  name: "PasienDetail",
  data() {
    // created dipanggil lebih dulu dari created()
    this.pasienMdl = new Pasien();
    this.objectMdl = this.pasienMdl;  // alias untuk fetchCreatedMixin
    let obv = this.pasienMdl.getObservables();
    obv.paramsId = this.$route.params.id;
    return obv;
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin"])
  },
  methods: {
    fetchData() {
      this.pasienMdl.load(this.paramsId);
    }
  },
  mixins: [onlineCheckMixin, fetchCreatedMixin]
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";

.capitalize {
  text-transform: capitalize;
}
</style>