import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "pasien";
const modelPath = `/perawatan/${modelName}/`;

class Pasien extends ModelBase {
  constructor() {
    let model = {
      id: null,
      no_rm: "",
      nama: "",
      gender: "",
      tgl_lahir: null,
      catatan: ""
    };
    let requiredFields = ["no_rm", "nama", "gender", "tgl_lahir"];
    super(modelName, model, requiredFields, ["catatan"], modelPath);
  }
}

export default Pasien;